$(document).ready(function () {

    /*
    BG HEADER
    */
    let screenHeight = screen.height;
    $(document).on('scroll', function () {
        if (window.location.pathname === '/') {
            if ($(document).scrollTop() > screenHeight - 100) {
                $("header").addClass("scrolled");
            } else {
                $("header").removeClass("scrolled");
            }
        }
    })

    /*
    * DATA ASIDE
    */
    $("#panels > div:not(:first-of-type):not(:last-of-type)").each(function (i) {
        if (i % 2 === 0) {
            $(this).find('.panel').attr('data-aside', 'white')
        } else {
            $(this).find('.panel').attr('data-aside', 'black')
        }
    })

    /*
    * MEGAMENU
    */
    $('.megamenu').on('click', function (e) {
        e.preventDefault()
        if ($(window).width() > 1024) {
            $('#megamenu').slideToggle()
        }
        else {
            $('#megamenu').removeClass('hidden')
        }
    })
    $(document).on("click", "#megamenu-back", function(){
        $('#megamenu').addClass('hidden')
    });

    /* Affichage sous-categories */
    if ($(window).width() < 1024) {
        // $(document).on("click", "#categories-main li", function(e){
        //     e.preventDefault()
        //     let id = $(this).data('categorie');
        //     $('#categories-sub ul').hide()
        //     $('#categories-sub ul[data-sous-categorie="' + id + '"]').show();
        // })
    }
    else {
        $(document).on("mouseenter", "#categories-main li", function(e) {
            e.preventDefault()
            $('#categories-articles ul').hide()
            let id = $(this).data('categorie');
            $('#categories-sub ul').hide()
            $('#categories-sub ul[data-sous-categorie="' + id + '"]').show();

            $('#categories-img a').hide()
            $('#categories-img a[data-image="' + id + '"]').show();
        })

        $(document).on("mouseenter", "#categories-sub li", function(e) {
            e.preventDefault()
            let id = $(this).data('sub');
            $('#categories-articles ul').hide()
            $('#categories-articles ul[data-article="' + id + '"]').show();
        })
    }
    /*
    MENU RESPONSIVE
     */
    $('#menu').on('click', function () {
        /*Ouvre le menu*/
        $('#nav').slideToggle();
        $('header.fixed').toggleClass('bg-opacity-50 bg-opacity-90')

        /*Change le picto*/
        let src = $(this).attr('src');
        let newsrc = (src == '/img/icones/menu.svg') ? '/img/icones/close.svg' : '/img/icones/menu.svg';
        $(this).attr('src', newsrc);

        /* Remonte tous les sous-menu */
        $('nav ul li > ul').slideUp();

        $('#categories-sub').removeClass('-left-1/2 min-w-screen').addClass('left-full')
    })
    // /* Ouverture des sous-menu */
    // if ($(window).width() < 1025) {
    //     $('#nav ul li a').on('click', function (e) {
    //         if ($(this).siblings('ul').length > 0) {
    //             e.preventDefault();
    //             $(this).siblings('ul').slideToggle();
    //             $(this).unbind("click");
    //         }
    //     })
    // }

    /*
    INPUT DYNAMIQUES
    */
    $('.input-checkbox span, .input-radio span, .input-switch span').on('click', function () {
        let input = $(this)[0].previousElementSibling
        input.checked = !input.checked;
    })

    /*
    ONGLETS
     */
    $('.onglet_title-item').on('click', function (e) {
        e.preventDefault();
        $('.onglet_title-item').removeClass('active')
        $(this).addClass('active');
        let title = $(this).data('title');
        $('.onglet_content-item').addClass('hidden');
        $('.onglet_content-item[data-content="' + title + '"]').removeClass('hidden');
    })

    /*
    TOGGLE PASSWORD
     */
    $('#show-pass').on('click', function () {
        //Change l'attribut du champ
        let attr = $(this).siblings('input').attr('type')
        let newAttr = (attr === 'password') ? 'text' : 'password';
        $(this).siblings('input').attr('type', newAttr)

        //Change le picto
        let classList = $(this).attr('class')
        let newClass = (classList === 'fas fa-eye cursor-pointer') ? 'fas fa-eye-slash cursor-pointer' : 'fas fa-eye cursor-pointer';
        $(this).attr('class', newClass);
    })

    /*
    ENLEVE STYLE P > IMG
    */
    $('p').has('img').css('all', 'unset');

    /*
    POPUP
     */
    $('.popup-open').on('click', function (e) {
        e.preventDefault();
        let id = $(this).data('id');
        $('#popup-' + id).fadeIn().css('display', 'grid')
    })
    /* Fermeture de la popup */
    $('.popup-close').on('click', function () {
        $('.popup').fadeOut();
    })
    $('.popup').on('click', function (e) {
        let popup = $('.popup-content');
        if (!popup.is(e.target) && popup.has(e.target).length === 0) {
            $('.popup').fadeOut();
        }
    });
    $(document).keyup(function (e) {
        if (e.keyCode === 27) {
            $('.popup').fadeOut();
        }
    });

    /* FILTRES MARQUES */
    $(':checkbox').change(function() {
        const marqueId = $(this).data('marque');
        $("article[data-id_marque="+marqueId+"]").toggle(this.checked);
        const articlesVisibles = $("article:visible").length;
        $('#aucun-article').toggle(articlesVisibles === 0);
    })

// Afficher le champ d'adresse si "Enlèvement à domicile" est sélectionné
    $('input[name="service_choice"]').on('change', function () {
        if ($('input[name="service_choice"]:checked').val() === 'domicile') {
            $('#address-fields').removeClass('hidden');
        } else {
            $('#address-fields').addClass('hidden');
        }
    });

// Afficher le champ "Autre équipement" si la case "Autre" est cochée
    $('#equipment_autre').on('change', function () {
        $('#other-equipment-field').toggleClass('hidden', !$(this).is(':checked'));
    });


});

/*
SLIDERS
 */
import Swiper, {Autoplay, Navigation, Pagination, Thumbs} from 'swiper';
import {gsap} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

Swiper.use([Navigation, Pagination, Thumbs, Autoplay]);

/* Slider hero */
const swiperHero = new Swiper('#slider-hero', {
    loop: true,
    autoplay: true,
    speed: 2000
})
const swiperActus = new Swiper('#slider-actus', {
    loop: true,
    autoplay: true,
    centeredSlides: true,
    navigation: {
        nextEl: '.swiper-actus_next',
        prevEl: '.swiper-actus_prev',
    },
    breakpoints: {
        760: {
            centeredSlides: false,
            slidesPerView: 2,
            spaceBetween: 25
        },
        1400: {
            centeredSlides: true,
            slidesPerView: 3,
            spaceBetween: 50
        }
    }
})
/* Swiper produits */
$(".swiper-produits").each(function (i) {

    const slider = new Swiper($(this)[0], {
        navigation: {
            nextEl: $(".swiper-produit_next")[i],
            prevEl: $(".swiper-produit_prev")[i],
        },
        breakpoints: {
            680: {
              slidesPerView: 2
            },
            1280: {
                slidesPerView: 3,
            },
            1600: {
                slidesPerView: 4,
            }
        }
    });

})

/* Swiper produits */
$(".swiper-categories").each(function (i) {

    const slider = new Swiper($(this)[0], {
        loop: true,
        autoplay: true,
        navigation: {
            nextEl: $(".swiper-categories_next")[i],
            prevEl: $(".swiper-categories_prev")[i],
        },
        breakpoints: {
            640: {
                slidesPerView: 2
            },
            1600: {
                slidesPerView: 3
            }
        }
    });

})

let swiperProduitAutres = new Swiper('#slider-autres-produits', {
    loop: true,
    autoplay: true,
    navigation: {
        nextEl: '.swiper-autres-produit_next',
        prevEl: '.swiper-autres-produit_prev',
    },
    breakpoints: {
        640: {
            slidesPerView: 2
        },
        1600: {
            slidesPerView: 3
        }
    }
});
const swiperProduitThumbs = new Swiper('#slider-produit-thumbs', {
    loop: true,
    autoplay: true,
    slideToClickedSlide: true,
    spaceBetween: 25,
    slidesPerView: 2,
    centeredSlides: false,
    navigation: {
        nextEl: '.swiper-produit_next',
        prevEl: '.swiper-produit_prev',
    },
    breakpoints: {
        800: {
            slidesPerView: 3,
        },
        1024: {
            slidesPerView: 2,
        },
        1280: {
            slidesPerView: 3,
        }
    }
})

const swiperProduitMain = new Swiper('#slider-produit-main', {
    loop: true,
    autoplay: true,
    centeredSlides: true,
    navigation: {
        nextEl: '.swiper-produit_next',
        prevEl: '.swiper-produit_prev',
    },
    thumbs: {
        swiper: swiperProduitThumbs,
    },
})
swiperProduitThumbs.params.control = swiperProduitMain;
swiperProduitMain.params.control = swiperProduitThumbs;


gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
/* Animation logo */
if (($(window).width() >= 1024) && (window.location.pathname === '/') ) {
    ScrollTrigger.saveStyles("#logo");
    let scale;
    if ($(window).width() < 1600) {
        scale = 0.25
    }
    else {
        scale = 0.35
    }
    gsap.timeline({
        scrollTrigger: {
            trigger: "#logo",
            scrub: 1,
            start: 'top center',
        }
    }).to('nav div', {
        duration: 1,
        width: '40%'
    })
    gsap.fromTo("#logo",
        {duration: 1},
        {
            onComplete: function () {
                gsap.to("#logo", {
                    scale: scale,
                    top: "-3.5rem",
                    scrollTrigger: {
                        trigger: "#logo",
                        start: "top top",
                        end: "+=20%",
                        scrub: true,
                    }
                })
            }
        });
}
/* Navigation */
if (($(window).width() >= 1024) && ($(window).width() < 1280) && (window.location.pathname === '/')) {

    const headerLinks = gsap.utils.toArray("nav a");
    /* Catégories */
    let panels = gsap.utils.toArray(".panel");

    gsap.utils.toArray(".panel").forEach((panel, i) => {
        ScrollTrigger.create({
            trigger: panel,
            start: "top top",
            toggleClass: {targets: panel, className: "active"},
            onLeave: () => {
                let headerColor = panels[i + 1].getAttribute('data-header');
                gsap.to(headerLinks, {color: headerColor});
            },
            onEnterBack: () => {
                let headerColor = panels[i].getAttribute('data-header');
                gsap.to(headerLinks, {color: headerColor});
            },
        });
    });
}
if (($(window).width() >= 1280) && (window.location.pathname === '/')) {
    const navLinks = gsap.utils.toArray("aside a"),
        headerLinks = gsap.utils.toArray("nav a"),
        asideLinks = gsap.utils.toArray("aside ul");
    navLinks.forEach((link, i) => {
        link.addEventListener("click", e => {
            e.preventDefault();
            $('aside a').removeClass('active')
            $(this).addClass('active')
            gsap.to(window, {scrollTo: i * innerHeight});
        });
    });
    /* Catégories */
    let panels = gsap.utils.toArray(".panel");

    gsap.utils.toArray(".panel").forEach((panel, i) => {
        ScrollTrigger.create({
            trigger: panel,
            start: "top top",
            pin: true,
            pinSpacing: false,
            toggleClass: {targets: panel, className: "active"},
            snap: 1 / panels - 1,
            onLeave: () => {
                let headerColor = panels[i + 1].getAttribute('data-header');
                let asideColor = panels[i + 1].getAttribute('data-aside');

                gsap.to(headerLinks, {color: headerColor});
                gsap.to(asideLinks, {color: asideColor});
                if (navLinks[i + 1]) {
                    gsap.to(navLinks[i + 1], {className: "active"});
                    gsap.to(navLinks[i], {className: ""});
                }
            },
            onEnterBack: () => {
                let headerColor = panels[i].getAttribute('data-header');
                let asideColor = panels[i].getAttribute('data-aside');
                gsap.to(headerLinks, {color: headerColor});
                gsap.to(asideLinks, {color: asideColor});
                gsap.to(navLinks[i], {className: "active"});
                if (navLinks[i + 1]) {
                    gsap.to(navLinks[i + 1], {className: ""});
                }
            },
        });
    });
}

let item = $('#services div')
gsap.to(item, {
    duration: .5,
    opacity: 1,
    stagger: .75,
})

